import * as React from 'react';
import useLocalStorage from 'react-use/lib/useLocalStorage';
import { LS_KEY_CART } from '../constants';
import { BillingAddress } from '../utils/mappers/address';

type Action =
  | { type: 'update'; cart: CartState }
  | { type: 'addItem'; item: LineItem }
  | { type: 'removeItem'; id: string }
  | { type: 'reset' }
  | { type: 'addBillingAddress'; address: BillingAddress };
type Dispatch = (action: Action) => void;
interface CartProviderProps {
  children: React.ReactNode;
}

export interface LineItem {
  name: string;
  id: string;
  quantity: number;
  price: number;
  initialPrice?: number;
}

type CartTotals = {
  quantity: number;
  price: number;
};

export interface CartState {
  lineItems?: LineItem[];
  billingAddress?: BillingAddress;
  total?: CartTotals;
}

function getTotals(items: LineItem[]): CartTotals {
  return items.reduce(
    (totals, current) => ({
      quantity: totals.quantity + current.quantity,
      price: totals.price + current.quantity * current.price,
    }),
    { quantity: 0, price: 0 }
  );
}

const CartStateContext = React.createContext<CartState | undefined>(undefined);
const CartDispatchContext = React.createContext<Dispatch | undefined>(
  undefined
);

function cartReducer(state: CartState, action: Action): CartState {
  switch (action.type) {
    case 'update': {
      return { ...state, ...action.cart };
    }
    case 'addItem': {
      const hasLineItems = state.lineItems && state.lineItems.length;

      if (!hasLineItems) {
        const lineItems = [action.item];

        return { ...state, lineItems, total: getTotals(lineItems) };
      }

      const lineItems = [...state.lineItems, action.item];

      return { ...state, lineItems, total: getTotals(lineItems) };
    }
    case 'removeItem': {
      const lineItems = state.lineItems.filter(item => item.id !== action.id);

      return {
        ...state,
        lineItems,
        total: getTotals(lineItems),
      };
    }
    case 'addBillingAddress': {
      return {
        ...state,
        billingAddress: action.address,
      };
    }
    case 'reset':
      return {};
    default: {
      throw new Error(`Unhandled action type`);
    }
  }
}

function CartProvider({ children }: CartProviderProps): React.ReactElement {
  const [localCart, saveLocalCart] = useLocalStorage<CartState>(
    LS_KEY_CART,
    {}
  );
  const [state, dispatch] = React.useReducer(cartReducer, localCart);

  React.useEffect(() => {
    saveLocalCart(state);
  }, [state, saveLocalCart]);

  return (
    <CartStateContext.Provider value={state}>
      <CartDispatchContext.Provider value={dispatch}>
        {children}
      </CartDispatchContext.Provider>
    </CartStateContext.Provider>
  );
}

function useCartState(): CartState {
  const context = React.useContext(CartStateContext);
  if (typeof context === 'undefined') {
    throw new Error('useCartState must be used within a CartProvider');
  }
  return context;
}

function useCartDispatch(): Dispatch {
  const context = React.useContext(CartDispatchContext);
  if (typeof context === 'undefined') {
    throw new Error('useCartDispatch must be used within a CartProvider');
  }
  return context;
}

export { CartProvider, useCartState, useCartDispatch };
