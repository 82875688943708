import { useEffect } from 'react';
import NProgress from 'nprogress';
import useEffectOnce from 'react-use/lib/useEffectOnce';

import Router from 'next/router';

export default function useRouterState() {
  useEffectOnce(() => {
    const isProductionUrl =
      process.env.NEXT_PUBLIC_APP_HOST === 'https://annaand.co';

    if (!isProductionUrl) {
      return;
    }
  });

  useEffect(() => {
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual';
    }

    NProgress.configure({
      template: '<div class="bar" role="bar"><div class="peg"></div></div>',
      showSpinner: false,
    });

    if ('scrollRestoration' in window.history) {
      const onRouteChangeStart = () => {
        NProgress.start();
        document.body.classList.add('routing');
      };

      const onRouteChangeComplete = () => {
        NProgress.done();
        document.body.classList.remove('routing');
      };

      const onRouterChangeError = () => {
        NProgress.done();
      };

      Router.events.on('routeChangeStart', onRouteChangeStart);
      Router.events.on('routeChangeComplete', onRouteChangeComplete);
      Router.events.on('routeChangeError', onRouterChangeError);

      return () => {
        Router.events.off('routeChangeStart', onRouteChangeStart);
        Router.events.off('routeChangeComplete', onRouteChangeComplete);
        Router.beforePopState(() => true);
      };
    }
  }, []);
}
