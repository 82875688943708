export const LS_KEY_CART = 'cart';

export const API_ROUTES_BASE =
  process.env.NODE_ENV === 'production'
    ? process.env.VERCEL_URL
    : process.env.API_ROUTES_BASE;

export const INFOPRODUCTO_TYPES = {
  guia: 'guias',
  clase: 'clases',
  curso: 'cursos',
  otro: 'recurso',
};
