import * as React from 'react';

interface MiniCartState {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const MiniCartContext = React.createContext<MiniCartState | undefined>(
  undefined
);

const MiniCartProvider = ({ children }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <MiniCartContext.Provider value={{ isOpen, setIsOpen }}>
      {children}
    </MiniCartContext.Provider>
  );
};

function useMinicartState() {
  const context = React.useContext(MiniCartContext);

  if (context === undefined) {
    throw new Error(
      'useMinicartContext must be used within a MiniCartProvider'
    );
  }

  return context;
}

export { MiniCartProvider, useMinicartState };
