import React from 'react';
import type { AppProps } from 'next/app';

import countries from 'i18n-iso-countries';
import es from 'i18n-iso-countries/langs/es.json';

import useRouterState from '@/utils/hooks/useRouterState';
import { darkerGrotesque, poppins } from '@/components/styled/text';

import '@reach/combobox/styles.css';
import '../common/css/nprogress.css';

import 'the-new-css-reset/css/reset.css';
import '../styles/main.scss';
import AppProviders from '../context';

type ExtendedAppProps = AppProps & { err: unknown };

const AnnaAndCoApp = ({
  Component,
  pageProps,
  err,
}: ExtendedAppProps): React.ReactElement => {
  countries.registerLocale(es);

  useRouterState();

  return (
    <>
      <style jsx global>{`
        :root {
          --poppins-font: ${poppins.style.fontFamily};
        }

        html {
          font-family: ${darkerGrotesque.style.fontFamily};
        }
      `}</style>
      <AppProviders>
        <div className="page-transition-wrapper">
          <Component {...pageProps} err={err} />
        </div>
      </AppProviders>
    </>
  );
};

export default AnnaAndCoApp;
