import React from 'react';
import Link from 'next/link';
import { PrismicProvider } from '@prismicio/react';
import { PrismicPreview } from '@prismicio/next';

import { repositoryName } from '@/lib/cms/prismic/prismicio';
import { defaultComponents } from '@/lib/cms/serializers/default';

import { UserProvider } from './user-context';
import { CartProvider } from './cart-context';
import { MiniCartProvider } from './miniCart';
import { CountriesProvider } from './countries';

function AppProviders({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement {
  return (
    <PrismicProvider
      richTextComponents={defaultComponents}
      internalLinkComponent={props => <Link {...props} />}
    >
      <PrismicPreview repositoryName={repositoryName}>
        <CountriesProvider>
          <UserProvider>
            <CartProvider>
              <MiniCartProvider>{children}</MiniCartProvider>
            </CartProvider>
          </UserProvider>
        </CountriesProvider>
      </PrismicPreview>
    </PrismicProvider>
  );
}

export default AppProviders;
